.mainfeatures {
  margin-top: 100px;
  width: 100%;
  padding-top: 100px;
  background-image: repeating-linear-gradient(to right,
      #B8B8B8,
      #B8B8B8 10px,
      transparent 10px,
      transparent 20px);
  background-size: 16px 1px;
  background-repeat: repeat-x;
  background-position: 0 0;
}

.mainfeatures-title {
  /* margin: 20px; */
  color: #2C2C2C;
  font-family: "Poppins500";
  font-size: 36px;
  text-align: center;
  text-transform: capitalize;
}

@media (max-width: 1200px) {
  .mainfeatures-title {
    font-size: 32px;
  }
}

@media (max-width: 992px) {
  .mainfeatures-title {
    font-size: 30px;
  }
}

@media (max-width: 768px) {
  .mainfeatures-title {
    font-size: 28px;
  }
}

@media (max-width: 576px) {
  .mainfeatures-title {
    font-size: 26px;
  }
}

@media (max-width: 476px) {
  .mainfeatures-title {
    font-size: 25px;
  }
}