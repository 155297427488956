/* Start Net System */

.netsystem {
    padding-top: 70px;
    position: relative;
   
}

.net-system-container {
    display: flex;
    justify-content: center;
    align-items: start;
    margin-top: 180px;
}

.net-system {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}
.net-system h1 {
    color: #2C2C2C;
    font-family: "Poppins500";
    font-size: 26px;
    text-transform: capitalize;
}
.net-system h1:not(:first-child) {
    margin-top: 40px;
}
.net-system p {
    color: #2C2C2C;
    font-family: "Poppins400";
    font-size: 16px;
    line-height: 28px;
    text-align: justify;
}

.net-system-img {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.net-system-img img {
    width: 70%;
}

.home-service-link {
    padding: 7px 32px;
    border: 1.6px solid #000;
    color: #000;
    font-family: "Poppins300";
    font-size: 13px;
    font-weight: 300;
    letter-spacing: 0.98px;
    text-transform: uppercase;
    cursor: pointer;
    margin-top: 20px;
    transition: .3s ease;
}
.home-service-link:hover {
    background: #000;
    color: #fff;
}

.solutions-img #hand {
    position: absolute;
    left: -108px;
    width: 100%;
    bottom: -283px;
}
.solutions-img #ball {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    width: 26%;
    bottom: 14px;
    animation: ballFly1400 infinite 3s;
}

@media (max-width: 1300px) {
    .solutions-img #ball {
        animation: ballFly infinite 3s;
    }
    .solutions-img #ball {
        left: 45%;
    }
} 
@media (max-width: 1200px) {
    .solutions-img #hand {
        left: -111px;
    }
    /* .solutions-img #ball {
        left: 50%;
    } */
}
@media (max-width: 992px) {
    .net-system-container {
        flex-direction: column-reverse;
        margin-top: 8px ;
    }
    .netsystem {
        padding-bottom: 395px;
    }
    .net-system-img {
        justify-content: center;
    }
    .net-system-img img {
        width: 60%;
    }
    .solutions-img #hand {
        width: 80%;
        bottom: unset;
        top: 220px;
        left: -123px;
    }
    .solutions-img #ball {
        width: 20%;
        left: 36%;
        bottom: -255px;
        animation: ballFly992 infinite 3s;
    }
}
@media (max-width: 767px) {
    .solutions-img #ball {
        width: 24%;
        left: 47%;
    }
    .solutions-img #hand {
        width: 90%;
        left: -93px;
    }
}
@media (max-width: 576px) {
    .net-system-container {
        margin-top: 60px;
    }
    .net-system  {
        align-items: center;
    }
    .net-system img {
        width: 100px;
    }
    .net-system h1 {
        font-size: 38px;
        line-height: 60px;
    }
    .net-system p {
        font-size: 17px;
        text-align: center;
    }
    .net-system-img {
        margin-top: 40px;
    }
    .solutions-img #hand {
        width: 84%;
        right: 0px;
        top: 194px;
    }
    .solutions-img #ball {
        left: 42%;
        width: 21%;
        animation: ballFly576 3s infinite ;
    }
}
@media (max-width: 490px) {
    .solutions-img #ball {
        left: 38%;
    }
}
@media (max-width: 476px) {
    .solutions-img #hand {
        top: 141px;
        left: -8px;
    }
    .solutions-img #ball {
        animation: ballFly476 3s infinite ;
        left: 54%;
    }
    .netsystem {
        padding-bottom: 387px;
    }
}


/* End Net System */




.solutions {
    margin-top: 200px;
    position: relative;
}
#arrow1 {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -172px;
    width: 25%;
}

.solutions-text:not(:first-child) {
    margin-top: 80px;
}
.solutions-text h1 {
    color: #2C2C2C;
    font-family: "Poppins700";
    font-size: 30px;
    letter-spacing: 3px;
    text-transform: uppercase;
}
.solutions-text p {
    color: #2C2C2C;
    font-family: "Poppins400";
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 1.8px;
}

.solutions-img {
    position: relative;
}

.solutionsimg {
    position: relative;
}


@media (max-width: 992px) {
    .solutions-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
    }
    .solutions {
        margin-top: 120px;
    }
    .solutions-text {
        text-align: center;
    }
    #arrow1 {
        display: none;
    }
}

@media (max-width: 576px) {
    .netsystem {
        padding-top: unset;
    }
    .solutions-text h1 {
        font-size: 26px;
    }
    .solutions-text p {
        font-size: 18px;
    }
    .net-system h1 {
        font-size: 24px;
    }
    .net-system p {
        font-size: 14px;
    }
    .home-service-link {
        padding: 6px 26px;
        font-size: 12px;
    }
}


@media (max-width: 476px) {
    .net-system-container {
        margin-top: 63px;
    }
} 







/* Animation */

@keyframes ballFly1400 {
    0% {
        bottom: 114px;
    }
    50%{
        bottom: 10px;
    }
    100% {
        bottom: 114px;
    }
}

@keyframes ballFly {
    0% {
        bottom: 64px;
    }
    50%{
        bottom: -35px;
    }
    100% {
        bottom: 64px;
    }
}

@keyframes ballFly992 {
    0% {
        bottom: -120px;
    }
    50%{
        bottom: -243px;
    }
    100% {
        bottom: -120px;
    }
}

@keyframes ballFly576 {
    0% {
        bottom: -146px;
    }
    50%{
        bottom: -255px;
    }
    100% {
        bottom: -146px;
    }
}

@keyframes ballFly476 {
    0% {
        bottom: -120px;
    }
    50% {
        bottom: -170px;
    }
    100% {
        bottom: -120px;
    }
}