.stack-div {
    margin-top: 120px;
    background-color:#1A1A1A;
    padding:40px 80px;
    color: #fff;
}
.stack-title {
    text-align: center;
    font-family: 'Poppins';
    font-size:30px;
    font-weight: 500;
    margin-bottom: 50px;
}
.stack-row {
    margin: 30px 80px;
    display: flex;
    justify-content: center;
}
.stack-row .col-md-4{
    padding-bottom: 15px;
}
.stack-row2 {
    margin: 0px 250px;
}
.stack-box {
    background-color: #242424;
    border: none;
    border-radius: 29px;
    padding: 15px 30px;
    text-align: center;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 500;
}
.lang {
    color: #717171;
    margin-top: 10px;
}
@media (max-width: 1400px) {}


@media (max-width: 1200px) {
    .stack-div {
        padding:40px;
    }
    .stack-row2 {
        margin: 0px 200px;
    }
    .stack-box {
        padding: 15px 20px;
        font-size: 14px;
    }
    .stack-title {
        font-size: 28px;
        margin-bottom: 40px;
    }
}


@media (max-width: 992px) {
    .stack-div {
        padding:40px 0px;
    }
    .stack-box {
        padding:20px 10px;
    }
    .stack-row {
        margin: 0px;
    }
    .stack-row2 {
        margin: 0px 70px;
    }
    .stack-box {
        margin-top:20px;
    }
    .stack-title {
        font-size: 26px;
        margin-bottom: 30px;
    }

}

@media (max-width: 768px) {
    .stack-box {
        margin-top: 25px;
    }
    .stack-row {
        margin: 0px 100px;
    }
    .stack-row2 {
        margin: 0px 100px;
    }
}

@media (max-width: 576px) {
    .stack-div {
        padding:40px 0px;
    }
    .stack-row {
        margin: 0px 20px;
    }
    .stack-row2 {
        margin: 0px 20px;
    }
    .stack-box {
        margin-top:20px;
    }
    .stack-title {
        font-size: 24px;
    }
}

@media (max-width: 476px) {
    .stack-div {
        padding: 40px 0px;
    }
    .stack-row {
        margin: 0px 20px;
    }
    .stack-row2 {
        margin: 0px 20px;
    }
    .stack-box {
        margin-top: 20px;
    }
    .stack-title {
        font-size: 22px;
    }
}