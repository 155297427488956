.special-solutions {
    margin-top: 80px;
    width: 100%;
    padding-top: 80px;
    background-image: repeating-linear-gradient(to right,
            #B8B8B8,
            #B8B8B8 10px,
            transparent 10px,
            transparent 20px);
    background-size: 16px 1px;
    background-repeat: repeat-x;
    background-position: 0 0;
}

.special-solutions-title {
    margin-bottom: 120px;
    text-align: center;
    color: #2C2C2C;
    font-family: "Poppins500";
    font-size: 32px;
    text-transform: capitalize;
    position: relative;
}

.special-solutions-title img {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 100%;
}

.special-solutions-second-title {
    color: #2C2C2C;
    font-family: "Poppins500";
    font-size: 26px;
    text-transform: capitalize;
    text-align: center;
}

.special-solutions-para {
    margin: 20px auto 0;
    max-width: 55%;
    color: #2C2C2C;
    text-align: center;
    font-family: "Poppins400";
    font-size: 16px;
    line-height: 28px;
}

.special-solutions-video {
    width: 80%;
    height: 550px;
    position: relative;
    margin: 30px auto 0;
    border: 20px solid #000;
    border-radius: 59px;
}

.special-solutions-video::after {
    content: "";
    position: absolute;
    width: 65%;
    height: 100%;
    border-radius: 29px;
    opacity: 0.06;
    background: #0C1F30;
    left: 43%;
    top: 15%;
    z-index: -1;
}

.special-solutions-iframe {
    width: 100%;
    height: 100%;
    border-radius: 33px;
    object-fit: cover;
}

@media (max-width: 992px) {
    .special-solutions-title {
        font-size: 30px;
    }
    .special-solutions-second-title {
        font-size: 24px;
        
    }
    .special-solutions-para {
        font-size: 14px;
        line-height: 26px;
        margin: 20px auto 0;
        max-width: 100%;
    }
    .special-solutions-video {
        width: 100%;
        height: 450px;
        margin: 40px auto 0;
    }
}

@media (max-width: 768px) {
    .special-solutions-para {
        margin: 20px 15px 0;
        max-width: 100%;
        font-size: 13px;
        line-height: 24px;
    }
    .special-solutions-title {
        font-size: 28px;
    }
    .special-solutions-second-title {
        font-size: 22px;
    }
    .special-solutions-video {
        width: 100%;
        height: 380px;
        border: 16px solid #000;
        border-radius: 50px;
    }
    .special-solutions-video::after {
        display: none;
    }
}

@media (max-width: 576px) {
    .special-solutions-title {
        font-size: 24px;
    }
    .special-solutions-second-title {
        font-size: 18px;
    }
    .special-solutions-para {
        font-size: 12px;
        line-height: 21px;
    }
    .special-solutions-video {
        width: 94%;
        height: 360px;
        border: 16px solid #000;
        border-radius: 50px;
    }
}

@media (max-width: 476px) {
    .special-solutions-title {
        font-size: 20px;
    }
    .special-solutions-second-title {
        font-size: 15px;
    }
    .special-solutions-video {
        width: 100%;
        height: 235px;
    }
}