.projectsList-project-content {
    height: 340px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 8px;
    margin-top: 35px;
}

.projectsList-project-container {
    padding: 0px 2px;
}

.projectsList-project-title-container {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
    
}

.projectsList-project-title-container span {
    color: #000;
    font-family: "Poppins500";
    font-size: 18px;
    transition: .3s;
}

.projectsList-project-des {
    color: #A6A6A6;
    font-family: "Poppins400";
    font-size: 16px;
    line-height: 25px;
}

.projectsList-project-container:hover .projectsList-project-title-container span , .projectsList-project-container:hover  {
    color: #674366;
    transition:.3s;
    cursor: pointer;
}

@media (max-width: 476px) {
    .projectsList-project-content {
        height: 200px;
    }
}