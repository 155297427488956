.home-page-navbar .show {
    color: #fff !important;
}
.navbar {
    padding-top: 30px;
    padding-bottom:30px;
    font-family: 'Inter';
    position: fixed;
    left: 0;
    right: 0;
    top: -40px;
    z-index: 10;
    margin-top: 40px;
    transition: top .2s ease-in-out;
}

.nav-scrolled {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    background: #fff;
    transition: top .2s ease-in-out;
    margin-top: -40px;
    top: 40px;
    box-shadow: 0px 4px 46px 0px rgba(0, 0, 0, 0.13);
}

.navbar-toggler {
    border: none !important;
}
.navbar-toggler:focus {
    outline: none !important;
    box-shadow: unset !important;
}
.navbar-toggler:focus-visible {
    outline: none !important;
}

.nav-pages {
    background: #fff !important;
    padding-bottom: 25px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    transition: top .4s ease-in-out;
    margin-top: unset;
}

.navbar-brand {
    color: #000;
    font-family: "Poppins700";
    font-size: 18px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 1.2px;
    text-transform: uppercase;
}
.nav-scrolled .navbar-brand {
    color: #fff;
    font-size: 16px;
}

.nav-scrolled .nav-home-logo {
    width: 40px;
}

.nav-scrolled .navbar-collapse {
    backdrop-filter: unset !important;
}

.nav-link {
    color: #000;
    font-family: "Poppins400";
    font-size: 12px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-left: 7px;
}
.nav-scrolled .nav-link {
    color: #000 !important;
    font-size: 12px;
    margin-left: 7px !important;
}
.home-page-navbar .nav-link {
    color: #fff;
    margin-left: 8px;
}


.navbar-nav-cust {
    margin-left:50px;
}


.home-page-navbar .navbar-nav-cust {
    margin-left: unset;
}
.home-page-navbar .navbar-brand {
    color: #fff;
}
/* .home-page-navbar .navbar-toggler-icon {
    background-image: url("../../asset/images/svg/menu.svg") !important;
} */

.home-logo {
    color: #FFF;
    font-family: "Poppins700";
    font-size: 20px;
    letter-spacing: 1.8px;
    margin-left: 30px;
    text-transform: uppercase;
}

.home-logo img {
    width: 55px;
    margin-top: -5px;
}

.home-page-navbar .navbar-toggler {
    border: unset !important;
}
.home-page-navbar .navbar-toggler:focus {
    box-shadow: unset !important;
}

.nav-scrolled .navbar-toggler {
    border: unset !important;
}
.nav-scrolled .navbar-toggler:focus {
    box-shadow: unset !important;
}
.navbar-toggler-icon-white {
    background-image: url('../../asset/images/svg/whiteMenu.svg') !important;
  }
  
  .navbar-toggler-icon-black {
    background-image: url('../../asset/images/svg/blackMenu.svg') !important;
  }

.logo-pages {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 5px;
}
.nav-home-logo {
    width: 65px;
}
.nav-home-enginia {
    display: none;
}
.nav-pages-logo {
    width: 50px;
}
.nav-pages-enginia {
    width: 120px;
    margin-bottom: -8px;
}

.navbar .active {
    position: relative;
    font-family: "Poppins700";
}
.navbar .active::after { 
    position: absolute;
    content: "";
    height: 2px;
    background: #000;
    width: 80%;
    left: 50%;
    transform: translateX(-50%);
    bottom: 5px;
}

.home-page-navbar .active {
    color: #fff !important;
}
.home-page-navbar .active::after {
    background: #fff;
}

.inside-pages-nav .active {
    color: #fff !important;
}
.inside-pages-nav .active::after {
    background: #fff;
}



.nav-link:focus-visible {
    box-shadow: unset !important;
}

.dropdown-item a {
    color: #000;
}

.inside-pages-nav .navbar-container {
    position: relative;
}
.inside-pages-nav .navbar-container:after {
    position: absolute;
    content: "";
    left: 50%;
    transform: translateX(-50%);
    width: 97%;
    height: 1px;
    background: #D2D2D2;
    bottom: -17px;
}

.inside-pages-nav .nav-link {
    color: #fff !important;
}

.nav-scrolled .navbar-container:after {
    display: none;
}

.nav-scrolled .nav-link {
    color: #000;
}
.nav-scrolled .active {
    color: #000 !important;
}
.nav-scrolled .active::after {
    background: #000;
}
.nav-scrolled .nav-link {
    color: #000 !important;
    font-size: 12px;
    margin-left: 7px !important;
}

.dropdown-item.active, .dropdown-item:active{
    background-color: #f8f9fa !important;
    color: #000;
}
@media (max-width: 1200px) {
    .nav-link {
        margin-left: 20px;
    }

}

@media (max-width: 992px) {
    .nav-link {
        margin-left: unset !important;
    }
    .navbar-collapse  {
        backdrop-filter: blur(3px) !important;
        margin-top: 5px;
    }
    .home-logo {
        display: none;
    }
    .navbar-nav-cust {
        margin-left: unset !important;
    }
    .active::after {
        display: none !important;
    }
}

@media (max-width: 476px) {
    
}