* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  width: 100%;
  overflow-x: hidden;
  
}

a {
  text-decoration: none !important; 
}

ul {
  padding: 0 !important;
}

@font-face {
  font-family: 'Poppins Bold';
  src: url("../src/asset/fonts/Poppins/Poppins-Bold.ttf");
}
@font-face {
  font-family: 'Poppins';
  src: url("../src/asset/fonts/Poppins/Poppins-Regular.ttf");
}
@font-face {
  font-family: 'Poppins Medium';
  src: url("../src/asset/fonts/Poppins/Poppins-Medium.ttf");
}
@font-face {
  font-family: 'Inter Thin';
  src: url("../src/asset/fonts/Inter-Thin.ttf");
}
@font-face {
  font-family: 'Inter';
  src: url("../src/asset/fonts/Inter-Regular.ttf");
}


/* Poppins */
@font-face {
  font-family: "Poppins100";
  src: url("./asset/fonts/Poppins/Poppins-Thin.ttf");
}
@font-face {
  font-family: "PoppinsItalic100";
  src: url("./asset/fonts/Poppins/Poppins-ThinItalic.ttf");
}
@font-face {
  font-family: "Poppins200";
  src: url("./asset/fonts/Poppins/Poppins-ExtraLight.ttf");
}
@font-face {
  font-family: "PoppinsItalic200";
  src: url("./asset/fonts/Poppins/Poppins-ExtraLightItalic.ttf");
}
@font-face {
  font-family: "Poppins300";
  src: url("./asset/fonts/Poppins/Poppins-Light.ttf");
}
@font-face {
  font-family: "PoppinsItalic300";
  src: url("./asset/fonts/Poppins/Poppins-LightItalic.ttf");
}
@font-face {
  font-family: "Poppins400";
  src: url("./asset/fonts/Poppins/Poppins-Regular.ttf");
}
@font-face {
  font-family: "PoppinsItalic400";
  src: url("./asset/fonts/Poppins/Poppins-Italic.ttf");
}
@font-face {
  font-family: "Poppins500";
  src: url("./asset/fonts/Poppins/Poppins-Medium.ttf");
}
@font-face {
  font-family: "PoppinsItalic500";
  src: url("./asset/fonts/Poppins/Poppins-MediumItalic.ttf");
}
@font-face {
  font-family: "Poppins600";
  src: url("./asset/fonts/Poppins/Poppins-SemiBold.ttf");
}
@font-face {
  font-family: "PoppinsItalic600";
  src: url("./asset/fonts/Poppins/Poppins-SemiBoldItalic.ttf");
}
@font-face {
  font-family: "Poppins700";
  src: url("./asset/fonts/Poppins/Poppins-Bold.ttf");
}
@font-face {
  font-family: "PoppinsItalic700";
  src: url("./asset/fonts/Poppins/Poppins-BoldItalic.ttf");
}
@font-face {
  font-family: "Poppins800";
  src: url("./asset/fonts/Poppins/Poppins-ExtraBold.ttf");
}
@font-face {
  font-family: "PoppinsItalic800";
  src: url("./asset/fonts/Poppins/Poppins-ExtraBoldItalic.ttf");
}
/* End Poppins */
