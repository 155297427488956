.erp-apps {
    margin-top: 100px;
    padding-top: 80px;
    background-image: repeating-linear-gradient(to right,
            #B8B8B8,
            #B8B8B8 10px,
            transparent 10px,
            transparent 20px);
    background-size: 16px 1px;
    background-repeat: repeat-x;
    background-position: 0 0;
}

.erp-apps-title {
    color: #2C2C2C;
    font-family: "Poppins500";
    font-size: 26px;
    text-align: center;
    text-transform: capitalize;
    margin-bottom: 60px;
}

.erp-apps-container {
    width: 92%;
    margin: 10px auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.erp-apps-container img {
    width: 100%;
    object-fit: contain;
}


@media (max-width: 992px) {
    .erp-apps-title {
        font-size: 24px;
    }
}

@media (max-width: 768px) {
    .erp-apps-title {
        margin-bottom: 30px;
        font-size: 22px;
    }
    .erp-apps-container {
        width: 100%;
    }
}

@media (max-width: 576px) {
    .erp-apps-title {
        font-size: 20px;
    }
}

@media (max-width: 476px) {
    .erp-apps-title {
        font-size: 18px;
    }
}