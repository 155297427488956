.home-services .home-service:last-child img {
    width: 80%;
    left: 60%;
    transform: translate(-50%, -50%);
}

.second-service-img {
    left: -82%;
    width: 1070px !important;
}

@media (max-width: 1400px) {
    .second-service-img {
        width: 920px !important;
        left: -77%;
    }
}

@media (max-width: 1200px) {
    .second-service-img {
        width: 850px !important;
        left: -93%;
    }
}

@media (max-width: 992px) {
    .first-service-img {
        width: 80%;
    }
    .second-service-img {
        width: 870px !important;
        left: -23%;
        top: -70px;
    }
    .home-services .home-service:last-child img {
        width: 63%;
        top: 71%;
    }
}

@media (max-width: 776px) {
    .second-service-img {
        width: 682px !important;
        left: -24%;
        top: -86px;
    }
    .home-services .home-service:last-child img {
        width: 75% !important;
        left: 56% !important;
    }
}

@media (max-width: 576px) {
    .second-service-img {
        width: 637px !important;
        left: -48%;
        top: -68px;
    }
    .projects-link {
        font-size: 12px;
    }
}

@media (max-width: 476px) {
    .second-service-img {
        width: 600px !important;
        left: -66%;
        top: -56px;
    }
    .home-services .home-service:last-child img {
        width: 75%;
        top: 71%;
    }
    .first-service-img {
        width: 97%;
    }
}

@media (max-width: 420px) {
    .home-service-title {
        font-size: 20px;
    }
    .home-service-para {
        font-size: 12px;
    }
    .home-service-link {
        font-size: 10px;
    }
}