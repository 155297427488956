.erp-module {
    padding: 10px 20px;
    margin-top: 10px;
    border-radius: 34px;
    background: rgba(212, 212, 212, 0.60);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.erp-module-title {
    color: #2C2C2C;
    font-family: "Poppins500";
    font-size: 18px;
    text-transform: capitalize;
    margin: 0 !important;
}

@media (max-width: 1200px) {
    .erp-module {
        padding: 8px 18px;
    }
    .erp-module-title {
        font-size: 16px;
    }
    .erp-module img {
        width: 18px;
    }
}

@media (max-width: 992px) {
    .erp-module-title {
        font-size: 15px;
    }
    .erp-module img {
        width: 17px;
    }
}

@media (max-width: 768px) {
    .erp-module-title {
        font-size: 14px;
    }
    .erp-module img {
        width: 15px;
    }
}

@media (max-width: 576px) {
    .erp-module {
        padding: 7px 16px;
        gap: 9px;
    }
    .erp-module-title {
        font-size: 13px;
    }
    .erp-module img {
        width: 14px;
    }
}

@media (max-width: 476px) {
    .erp-module {
        padding: 6px 14px;
        gap: 8px;
    }
    .erp-module-title {
        font-size: 11px;
    }
    .erp-module img {
        width: 10px;
    }
}