.hero-div {
    position: relative;
}

.project-details-container {
    padding: 100px 0;
}

.hero-background {
    position: absolute;
    width: 93%;
    top: -261px;
    right: -500px;
}

.project-type-btn {
    background-color: rgba(0, 0, 0, 0.12);
    color: rgba(44, 44, 44, 1);
    font-family: 'Poppins';
    padding: 5px 20px;
    font-size: 14px;
    border-radius: 19px;
    border: none;
}

.project-type-div {
    display: flex;
    gap: 10px;
}

.project-title {
    font-size: 50px;
    font-family: 'Poppins';
    font-weight: 300;
    color: #000;
    margin: 20px 0px 5px;
}

.project-des {
    color: #2C2C2C;
    font-family: 'Poppins';
    font-size: 17px;
    width: 80%;
}

.apps {
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
}

.hero-img-col {
    position: relative;
}

.hero-img {
    position: absolute;
    top: -173px;
    right: 0px;
    width: 57%;
    z-index: 100;

}

.app-container {
    margin-top: 100px;
}

.app-title {
    color: #2C2C2C;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 30px;
    margin-bottom: 20px;
}

.app-text {
    color: #2C2C2C;
    font-family: 'Poppins';
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 0 !important;
}

.app-web-links {
    max-width: 52%;
}

.btn-website, .btn-website-noapp {
    display: block;
    background-color: inherit;
    padding: 8px 30px;
    width: 100%;
    color: #000;
    border: 1.6px solid #000;
    margin-top: 30px;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 300;
    text-align: center;
    transition: .3s ease;
}
.btn-website:hover {
    background: #000;
    color: #fff;
}
.btn-website-noapp:hover {
    background: #000;
    color: #fff;
}

@media (max-width: 2000px) {
    .hero-background {
        position: absolute;
        width: 1200px;
        top: -276px;
        right: -427px;
    }
    .app-container {
        margin-top: 200px;
    }
}


@media (max-width: 1800px) {
    .hero-background {
        position: absolute;
        width: 1186px;
        top: -261px;
        right: -403px;
    }
    .app-container {
        margin-top: 180px;
    }
}

@media (max-width: 1600px) {
    .app-container {
        margin-top: 150px;
    }
}

@media (max-width: 1400px) {
    .hero-img {
        top: -13px;
        right: -37px;
        width: 60%;
    }
    .hero-background {
        width: 1065px;
        top: -221px;
        right: -366px;
    }
    .app-web-links {
        max-width: 61%;
    }
    .app-container {
        margin-top: 100px;
    }
}


@media (max-width: 1200px) {
    .project-title {
        font-size: 41px;
        margin: 14px 0px 5px
    }
    .hero-img {
        top: 28px;
        right: 0;
        width: 58%;
    }
    .hero-background {
        width: 868px;
        top: -107px;
        right: -336px;
    }
    .app-image {
        width: 135px;
    }
    .btn-website, .btn-website-noapp {
        font-size: 13px;
        padding: 7px 30px;
        margin-top: 25px;
    }
    .project-des {
        font-size: 15px;
    }
}


@media (max-width: 992px) {
    .btn-website, .btn-website-noapp {
        font-size: 12px;
        padding: 6px 30px;
        margin-top: 19px;
    }
    .hero-img {
        top: 29px;
        right: -47px;
        width: 60%;
    }
    .hero-background {
        width: 712px;
        top: -26px;
        right: -249px;
    }
    .app-container {
        margin-top: 100px;
    }
    .project-des {
        width: 100%;
        font-size: 14px;
    }
    .app-image {
        width: 115px;
    }
    .app-web-links {
        max-width: 71%;
    }
    .project-title {
        font-size: 36px;
    }
    .project-type-btn {
        font-size: 13px;
        padding: 4px 16px;
    }
    .app-text {
        font-size: 14px;
        text-align: justify;
    }
}

@media (max-width: 768px) {
    .project-intro-div {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .project-des {
        text-align: center;
    }
    .hero-img {
        top: 354px;
        right: -47px;
        width: 100%;
    }
    .hero-background {
        width: 670px;
        top: 227px;
        right: -304px;
        z-index: -1;
    }
    .project-details-container {
        padding: 100px 0px 250px;
    }
    .app-container {
        margin-top: 130px;
    }
    .app-image {
        width: 125px;
    }
    .btn-website {
        margin: 27px auto 0;
        width: 70%;
        padding: 8px 30px;
    }
    .btn-website-noapp {
        width: 100%;
        padding: 8px 30px;
        margin: 27px auto 0;
    }
    .apps {
        gap: 20px;
    }
}

@media (max-width: 576px) {
    .project-details-container {
        padding: 56px 0px 207px;
    }
    .hero-img {
        top: 331px;
        right: 0px;
        width: 100%;
    }
    .hero-background {
        width: 510px;
        top: 236px;
        right: -203px;
    }
    .app-container {
        margin-top: 115px;
    }
    .project-type-btn {
        font-size: 12px;
    }
    .project-title {
        font-size: 34px;
        margin: 10px 0px 5px;
    }
    .project-des {
        font-size: 16px;
    }
    .app-title {
        font-size: 28px;
        margin-bottom: 11px;
    }
    .app-text {
        font-size: 13px;
    }
}

@media (max-width: 476px) {
    .hero-background {
        width: 430px;
        top: 265px;
        right: -183px;
    }
    .project-details-container {
        margin-top: 120px;
    }
    .project-des {
        font-size: 13px;
    }
    .project-title {
        font-size: 30px;
    }
    .btn-website, .btn-website-noapp {
        font-size: 11px;
        padding: 7px 30px;
    }
    .app-title {
        font-size: 24px;
        margin-bottom: 8px;
    }
}




