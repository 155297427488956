.ai-services-container {
    padding-top: 50px;
    background-image: repeating-linear-gradient(to right, #B8B8B8, #B8B8B8 10px, transparent 10px, transparent 20px);
    background-size: 16px 1px;
    background-repeat: repeat-x;
    background-position: 0 0;
}

.ai-services-title-content {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    gap: 14px;
}

.ai-services-title-1 {
    color: #2C2C2C;
    font-family: "Poppins500";
    font-size: 30px;
}

.ai-services-title-2 {
    width: 30%;
    color: #565656;
    text-align: center;
    font-family: "Poppins400";
    font-size: 14px;
    line-height: 22px;
}

/* ai-services-list Start*/
.ai-services-list {
    padding-top: 80px;
}

.ai-service-content {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.ai-service-title {
    display: flex;
    gap: 15px;
    align-items: center;
}

.ai-service-title-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ai-service-title-icon img {
    width: 22px;
}

.ai-service-title-des {
    color: #2C2C2C;
    font-family: "Poppins500";
    font-size: 16px;
}

.ai-service-des {
    color: #2C2C2C;
    text-align: justify;
    font-family: "Poppins400";
    font-size: 14px;
    line-height: 23px;
}
.ai-service-container {
    margin-bottom: 60px;
    padding: 0px 40px;
}
.ai-services-arrow  {
    display: flex;
    justify-content: center;
}   
.ai-services-chatbot-button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 18px;
    padding-top: 25px;
    padding-bottom: 25px;
}

@keyframes float {
    0%, 100% {
        transform: translate(0, -6px);
    }
    50% {
        transform: translate(0, 6px);
    }
}

.ai-services-chatbot-button img {
    animation: float 3s ease-in-out infinite;
}

.ai-services-chatbot-button-text {
    color: #2C2C2C;
    font-size: 20px;
    font-family: "Poppins500";
}

.ai-services-chatbot-image-content img {
    width: 100%;
   
}
.ai-services-chatbot-des {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
}

.ai-services-chatbot-des span {
    color: #2C2C2C;
    text-align: center;
    font-family: "Poppins400";
    font-size: 14px;
    line-height: 23px;
    width: 57%;
}

.ai-services-chatbot-tasks-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ai-services-chatbot-tasks-content img {
    width: 75%;
}
.ai-services-chatbot-tasks-container {
    padding-top: 40px;
}
.ai-services-chatbot-tasks-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ai-services-chatbot-tasks-content img {
    width: 40%;
    border-radius: 8px;
    background: lightgray -610.417px -200.317px / 248.071% 1106.288% no-repeat;
    box-shadow: 0px 4px 74px 0px rgba(0, 0, 0, 0.25);
}

.ai-services-chatbot {
    padding-bottom: 40px;
}

.ai-services-chatbot-button img {
    width: 70px;
}

.ai-services-arrow img  {
    width: 50px;
}   

@media (max-width: 768px) {
    .ai-services-title-2 {
        width: 78%;
    }
    .ai-services-list {
        padding-top: 45px;
    }
    .ai-service-container {
        margin-bottom: 27px;
        padding: 0px 27px;
    }

    .ai-services-chatbot-des span {
        width: 100%;
    }
    .ai-services-chatbot-tasks-content img{
        width: 100%;
    }
    .ai-services-chatbot-image-content img {
        width: 160%;
        margin-left: -7px;
    }
}