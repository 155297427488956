.team-card {
    margin-top: 40px;
}

.team-card-image { 

}

.team-card-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.team-card-name {
    margin-top: 12px;
    color: #202020;
    font-family: "Poppins400";
    font-size: 21px;
}

.team-card-title {
    margin-top: 10px;
    margin-bottom: 5px;
    color: #696969;
    font-family: "Poppins500";
    font-size: 16px;
}

.team-card-para {
    margin: 0;
    color: #696969;
    font-family: "Poppins300";
    font-size: 14px;
}