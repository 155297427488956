.feature-container {
    width: 90%;
    margin: 55px auto 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    height: 367px;
}

.feature-title {
    color: #2C2C2C;
    text-align: center;
    font-family: "Poppins500";
    font-size: 17px;
    text-transform: capitalize;
    line-height: 25px;
}

.feature-para {
    text-align: center;
    color: #2C2C2C;
    font-family: "Poppins400";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
}

.feature-image {
    margin-top: 20px;
    width: 100%;
}

@media (max-width: 1400px) {
    .feature-para {
        line-height: 22px;
        font-size: 13px;
    }
    .feature-title {
        font-size: 15px;
        line-height: 22px;
    }
}

@media (max-width: 1200px) {
    .feature-container {
        height: 320px;
    }
}

@media (max-width: 992px) {
    .feature-container {
        height: 331px;
    }
}

@media (max-width: 768px) {
    .feature-image {
        width: 80%;
        object-fit: contain;
    }
}

@media (max-width: 567px) {
    .feature-container {
        width: 90%;
        margin: 40px auto 0;
    }
}

@media (max-width: 476px) {
    .feature-container {
        height: 311px;
    }
    .feature-title {
        margin-bottom: unset !important;
        font-size: 14px;
    }
    .feature-image {
        margin-top: unset;
    }
    .feature-para {
        line-height: 20px;
        font-size: 12px;
    }
}