.mobile-about-service-container{
    padding-bottom: 50px;
}
.mobile-about-service-title {
    color: #2C2C2C;
    font-family: "Poppins500";
    font-size: 25px;
    text-transform: capitalize;
}
.mobile-about-service-des {
    color: #2C2C2C;
    text-align: justify;
    font-family: "Poppins400";
    font-size: 14px;
    line-height: 24px;
}
.mobile-about-service-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
@media (max-width: 768px) {
    .mobile-about-service-content {
        align-items: center;
    }
    .mobile-about-service-des {
        text-align: center;
    }
}

