.projectsList-header {
    padding-top: 130px;
}


.projectsList-header-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.projectsList-header-content h2 {
    color: #000;
    font-family: "Poppins500";
    font-size: 25px;
}

.projectsList-header-content span {
    text-align: center;
    width: 48%;
    color: #2C2C2C;
    font-family: "Poppins400";
    font-size: 16px;
    line-height: 25px;
    max-width: 88%;
}


@media (max-width: 992px) {
    .projectsList-header-content span {
        width: 100%;
    }
}

