.our-team-para {
    margin-top: 60px;
}

.our-team-title {
    color: #2C2C2C;
    font-family: "Poppins500";
    font-size: 36px;
    text-transform: capitalize;
}

.our-team-desc {
    margin-top: 25px;
    color: #2C2C2C;
    text-align: justify;
    font-family: "Poppins400";
    font-size: 16px;
    line-height: 28px; 
}