.erp-integrates {
    margin-top: 160px;
    width: 100% ;
    padding-top: 80px;
    background-image: repeating-linear-gradient(
        to right,
        #B8B8B8,
        #B8B8B8 10px, 
        transparent 10px,
        transparent 20px 
      );
      background-size: 16px 1px;
      background-repeat: repeat-x;
      background-position: 0 0; 
}
#color5 {
  position: absolute;
  left: 0px;
  top: -650px;
  z-index: -1;
}