.system-data {
    margin-top: 80px;
    width: 100%;
    padding-top: 80px;
    background-image: repeating-linear-gradient(to right,
            #B8B8B8,
            #B8B8B8 10px,
            transparent 10px,
            transparent 20px);
    background-size: 16px 1px;
    background-repeat: repeat-x;
    background-position: 0 0;
}

.system-data-title {
    color: #2C2C2C;
    text-align: center;
    font-family: "Poppins500";
    font-size: 26px;
    text-transform: capitalize;
}

.system-data-para {
    margin: 20px auto 0;
    max-width: 54%;
    color: #2C2C2C;
    text-align: center;
    font-family: "Poppins400";
    font-size: 16px;
    line-height: 28px;
}

.system-data-video {
    width: 80%;
    height: 550px;
    margin: 30px auto 0;
    border: 20px solid #000;
    border-radius: 59px;
}

.system-data-iframe {
    width: 100%;
    height: 100%;
    border-radius: 33px;
    object-fit: cover;
}

@media (max-width: 1200px) {
    .system-data-para {
        max-width: 67%;
        font-size: 15px;
        line-height: 27px;
    }
}

@media (max-width: 992px) {
    .system-data-title {
        font-size: 24px;
    }
    .system-data-video {
        width: 100%;
        height: 450px;
    }
    .system-data-para {
        margin: 20px auto 0;
        max-width: 100%;
    }
}

@media (max-width: 768px) {
    .system-data-title {
        font-size: 22px;
    }
    .system-data-para {
        font-size: 14px;
        line-height: 25px;
    }
    .system-data-video {
        width: 95%;
        height: 380px;
        border: 12px solid #000;
        border-radius: 47px
    }
}

@media (max-width: 576px) {
    .system-data-title {
        font-size: 20px;
    }
    .system-data-para {
        font-size: 13px;
        line-height: 22px;
    }
    .system-data-video {
        height: 280px;
        border-radius: 39px;
    }
    .system-data-iframe {
        border-radius: 25px;
        
    }
}

@media (max-width: 476px) {
    .system-data-title {
        font-size: 18px;
    }
    .system-data-para {
        font-size: 12px;
        line-height: 20px;
    }
    .system-data-video {
        height: 212px;
        border-radius: 28px
    }
    .system-data-iframe {
        border-radius: 14px;
    }
}