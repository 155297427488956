.loading-container {
    width: 100%;
    height: 98vh;
    position: relative;
}

.loading-container svg {
    position: absolute !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
    width: 200px !important;
    height: 200px !important;
}