.website-services {
    padding-top: 100px;
}
.website-service-col-content {
    display: flex;
    justify-content: center;
}
.website-service-container {
    height: 100%;
}
.website-service-col-content {
    height: 100%;
    display: flex;
    align-items: center;
}

.website-service-text-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0px 50px;
}
.arrow-container {
    display: flex;
    justify-content: center;
}

.website-service-col-content img {
    width: 80%
}

.website-services .arrow-container {
    margin: 90px 0px;
}
.arrow-container img {
    width: 250px;
} 

.website-service-text-title {    
    color: #2C2C2C;
    font-family: "Poppins500";
    font-size: 25px;
    text-transform: capitalize;
}

.website-service-text-des {
    color: #2C2C2C;
    text-align: justify;
    font-family: "Poppins400";
    font-size: 14px;
    line-height: 24px;
}


@media (max-width: 768px) {

    .stack-div {
        margin-top: 30px;
    }
    .website-services {
        padding-top: 50px;
    }
    .website-service-text-content {
        padding: 15px;
        align-items: center;
    }
    .website-service-text-title {
        font-size: 22px;
        text-align: center;
    }

    .website-service-text-des {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .website-service-text-des span {
        text-align: center;
    }
    .arrow-container {
        display: none;
    }

    .website-services-row.odd-row {
        flex-direction: column-reverse !important;
      }
      .website-services-row {
        gap: 20px;
        margin-bottom: 55px;
      }
    
}

