
.App {
position: relative;
overflow: hidden;
max-width: 100%;
opacity: 0.3;
transition: opacity 1s ease-in-out;
}


  
  .App.show-content {
    opacity: 1;
  }
  
  .loading-container.fade-out {
    opacity: 0;
    transition: opacity 0.8s ease-in-out;
  }